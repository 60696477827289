var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "textMedium--text mb-5" }, [
      _vm._v(
        " This fee is charged by the XinFin - XDC network and fluctuates depending on network traffic. XOW does not profit from this fee. "
      ),
    ]),
    _c(
      "div",
      _vm._l(_vm.buttons, function (b, key) {
        return _c(
          "div",
          {
            key: key,
            staticClass:
              "mb-2 d-flex align-center justify-space-between group-button",
            class: [_vm.gasPriceType === b.title ? "active" : ""],
            attrs: { id: _vm.$data[b.title + "Disabled"] ? "disabled" : "" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return function () {
                  _vm.setSelected(b.title)
                }.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "d-flex align-center" }, [
              _c(
                "div",
                {
                  staticClass: "mr-1 ml-n1 text-center",
                  staticStyle: { width: "40px", "line-height": "0" },
                },
                [
                  b.title === _vm.gasPriceTypes.ECONOMY
                    ? _c("v-icon", { attrs: { color: "textDark" } }, [
                        _vm._v(" mdi-check "),
                      ])
                    : _vm._e(),
                  b.title === _vm.gasPriceTypes.REGULAR
                    ? _c("img", {
                        class: _vm.isDark ? "dark-icon" : "",
                        attrs: {
                          src: require("@/assets/images/icons/icon-arrow-up.svg"),
                          alt: "arrow up",
                          height: "15",
                        },
                      })
                    : _vm._e(),
                  b.title === _vm.gasPriceTypes.FAST
                    ? _c("img", {
                        class: _vm.isDark ? "dark-icon" : "",
                        attrs: {
                          src: require("@/assets/images/icons/icon-arrow-up.svg"),
                          alt: "arrow up",
                          height: "15",
                        },
                      })
                    : _vm._e(),
                  b.title === _vm.gasPriceTypes.FAST
                    ? _c("img", {
                        class: _vm.isDark ? "dark-icon" : "",
                        attrs: {
                          src: require("@/assets/images/icons/icon-arrow-up.svg"),
                          alt: "arrow up",
                          height: "15",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "mew-heading-3 font-weight-regular textDark--text",
                  },
                  [_vm._v(" " + _vm._s(b.priority) + " ")]
                ),
                !_vm.fromSettings
                  ? _c("div", { staticClass: "prices d-flex" }, [
                      b.title === _vm.gasPriceTypes.ECONOMY
                        ? _c("div", { staticClass: "secondary--text mr-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.economyInEth) +
                                " " +
                                _vm._s(_vm.currencyName) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      b.title === _vm.gasPriceTypes.REGULAR
                        ? _c("div", { staticClass: "secondary--text mr-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.regularInEth) +
                                " " +
                                _vm._s(_vm.currencyName) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      b.title === _vm.gasPriceTypes.FAST
                        ? _c("div", { staticClass: "secondary--text mr-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.fastInEth) +
                                " " +
                                _vm._s(_vm.currencyName) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      b.title === _vm.gasPriceTypes.ECONOMY
                        ? _c("div", { staticClass: "textLight--text" }, [
                            _vm._v(" " + _vm._s(_vm.economyInUsd) + " "),
                          ])
                        : _vm._e(),
                      b.title === _vm.gasPriceTypes.REGULAR
                        ? _c("div", { staticClass: "textLight--text" }, [
                            _vm._v(" " + _vm._s(_vm.regularInUsd) + " "),
                          ])
                        : _vm._e(),
                      b.title === _vm.gasPriceTypes.FAST
                        ? _c("div", { staticClass: "textLight--text" }, [
                            _vm._v(" " + _vm._s(_vm.fastInUsd) + " "),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-1",
                    attrs: { color: "greenPrimary", small: "" },
                  },
                  [_vm._v("mdi-clock-outline")]
                ),
                _c("div", { staticClass: "greenPrimary--text" }, [
                  _vm._v(_vm._s(b.time)),
                ]),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "mt-4 d-flex flex-column align-center" }, [
      !_vm.fromSettings && _vm.showGetMoreEth
        ? _c("div", { staticClass: "mt-3" }, [
            _c("span", { staticClass: "secondary--text" }, [
              _vm._v("Can't increase priority? "),
            ]),
            _vm.network.type.canBuy
              ? _c("a", { on: { click: _vm.openBuySell } }, [
                  _vm._v(
                    " Buy more " + _vm._s(_vm.network.type.currencyName) + " "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }